import axios from 'axios';
import exp from 'constants';
import moment from 'moment';
import 'moment/locale/ko';

// const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = "http://172.30.1.17:3030"
// const API_URL = 'http://localhost:3030';
// const API_URL = "http://121.170.153.66:8080"
// const API_URL = 'http://172.30.1.7q8:9090';

//const API_URL = 'http://10.22.10.72';
const API_URL = 'https://dev.springtennis.co';
//const API_URL = 'http://localhost:8080';



const TENNIS_APP_API_URL = process.env.REACT_APP_TENNIS_APP_API_URL;
const WORKER_URL = 'http://localhost:5000';


export const getPatientList = async (keyword:string|undefined, doctor:string|undefined,
  wardroom:string|undefined,
  isChecked:string, isSent:string,
  startIndex:number|string, numberOfItems:number|string) => {

  if (Number(startIndex) < 1) {
    return console.error('startIndex는 1이상의 정수입니다');
  }
  if (Number(numberOfItems) < 1) {
    return console.error('numberOfItems는 1이상의 정수입니다');
  }

  let query = `?startIndex=${startIndex}&numberOfItems=${numberOfItems}`; 
  if (keyword) query += `&keyword=${keyword}`;
  if (doctor) query += `&doctor=${doctor}`;
  if (wardroom) query += `&wardRoom=${wardroom}`;
  if (isChecked) query += `&isChecked=${isChecked}`;
  if (isSent) query += `&isSent=${isSent}`;
  
  try {
    const getResult = await axios.get(`${API_URL}/api/patient${query}`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getDoctorList = async () => {
  try {
    const getResult = await axios.get(`${API_URL}/api/doctor`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  } 
}

export const getWardRoomList = async () => {
  try{
    const getResult = await axios.get(`${API_URL}/api/wardRoom`);
    return getResult.data;  
  }catch (error) {
    console.log(error);
    return { error: error };
  }
}

export const getPatient = async (patientId: number) => {
  try {
    const getResult = await axios.get(`${API_URL}/api/patient/${patientId}`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createPatient = async (patient: any) => {
  try {
    const result = await axios.post(
      `${API_URL}/api/patient`,
      { patient },
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updatePatient = async (patientId: number|undefined, patient: any) => {

  try {
    const result = await axios.put(
      `${API_URL}/api/patient/${patientId}`,
      { patient },
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getDiagnosisList = async (patientId: number) => {
  try {
    const getResult = await axios.get(`${API_URL}/api/patient/${patientId}/diagnosisList`); 
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateDiagnosisList = async (patientId: number|undefined, diagnosisList: any) => {
  try {
    const result = await axios.put(
      `${API_URL}/api/patient/${patientId}/diagnosisList`,
      { patientId, diagnosisList },
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getNursingDiagnosisList = async (patientId: number) => {
  try {
    const getResult = await axios.get(`${API_URL}/api/patient/${patientId}/nursingDiagnosisList`); 
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateNursingDiagnosisList = async (patientId: number|undefined, nursingDiagnosisList: any) => {
  try {
    const result = await axios.put(
      `${API_URL}/api/patient/${patientId}/nursingDiagnosisList`,
      { patientId, nursingDiagnosisList },
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getMedicationList = async (patientId: number) => {
  try {
    const getResult = await axios.get(`${API_URL}/api/patient/${patientId}/medicationList`); 
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateMedicationList = async (patientId: number|undefined, admDrugList: any, dischDrugList: any) => {
  try {
    const result = await axios.put(
      `${API_URL}/api/patient/${patientId}/medicationList`,
      { patientId, admDrugList, dischDrugList },
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getDiagnosticTest = async (patientId: number) => {
  try {
    const getResult = await axios.get(`${API_URL}/api/patient/${patientId}/diagnosticTest`); 
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getDiagnosticTestList = async (patientId: number) => {
  try {
    const getResult = await axios.get(`${API_URL}/api/patient/${patientId}/diagnosticTestList`); 
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createDiagnosticTest =  async (patientId: number|undefined, diagnosticTest: any) => {
  try {
    const result = await axios.post(
      `${API_URL}/api/patient/${patientId}/diagnosticTest`,
      { patientId, diagnosticTest },
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};


export const updateDiagnosticTest = async (patientId: number|undefined, diagnosticTest: any) => {
  try {
    const result = await axios.put(
      `${API_URL}/api/patient/${patientId}/diagnosticTest`,
      { patientId, diagnosticTest },
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  } 
}

export const updateDiagnosticTestList = async (patientId: number|undefined, diagnosticTestList: any) => {
  try {
    const result = await axios.put(
      `${API_URL}/api/patient/${patientId}/diagnosticTestList`,
      { patientId, diagnosticTestList },
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  } 
}

export const getUserList = async (keyword: string|undefined, type:string|undefined, startIndex: number|string, numberOfItems: number|string) => {
  if (Number(startIndex) < 1) {
    return console.error('startIndex는 1이상의 정수입니다');
  }
  if (Number(numberOfItems) < 1) {
    return console.error('numberOfItems는 1이상의 정수입니다');
  }

  let query = `?startIndex=${startIndex}&numberOfItems=${numberOfItems}`; 
  if (keyword) query += `&keyword=${keyword}`;
  if (type) query += `&type=${type}`;

  console.log("query: ", query);

  try {
    const getResult = await axios.get(`${API_URL}/api/user${query}`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
} 

export const getUser = async (userId: number) => {
  try {
    const getResult = await axios.get(`${API_URL}/api/user/${userId}`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createUser = async (user: any) => {
  try {
    const result = await axios.post(
      `${API_URL}/api/user`,
      { user },
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
}

export const updateUser = async (userId: number|undefined, user: any) => {
  try {
    const result = await axios.put(
      `${API_URL}/api/user/${userId}`,
      { user },
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
}


export const getMyInfo = async () => {
  try {
    const getResult = await axios.get(`${API_URL}/api/me`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getAllLessonList = async (
  branchId: number,
  startIndex: number,
  numberOfItems: number,
  lessonType?:
    | 'all'
    | 'onePerson'
    | 'twoPersons'
    | 'threePersons'
    | 'coupon'
    | 'ball'
    | string,
  lessonStatus?: 'all' | 'done' | 'notYet' | 'cancel' | string,
  keyword?: string,
  staffId?: 'all' | number,
  regMonth?: string
) => {
  if (startIndex < 1) {
    return console.error('startIndex는 1이상의 정수입니다');
  }
  if (numberOfItems < 1) {
    return console.error('numberOfItems는 1이상의 정수입니다');
  }
  try {
    const getAllLessonListResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/lesson/allLessonList?` +
        `orderType=101` +
        (keyword ? `&keyword=${keyword}` : '') +
        (lessonType === 'all' ? '' : `&lessonType=${lessonType}`) +
        (lessonStatus === 'all' ? '' : `&lessonStatus=${lessonStatus}`) +
        `&staffId=${staffId || 'all'}` +
        `&startIndex=${startIndex}` +
        `&numberOfItems=${numberOfItems}` + 
        (regMonth === 'all' ? '' : `&regMonth=${regMonth}`)
    );
    return {
      lessonList: getAllLessonListResult.data.data,
      size: getAllLessonListResult.data.size,
    };
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getLessonInfo = async (lessonId: number) => {
  try {
    const getLessonInfoResult = await axios.get(
      `${API_URL}/api/lesson/${lessonId}`,
    );
    return {
      lesson: getLessonInfoResult.data?.lesson,
      scheduleList: getLessonInfoResult.data?.scheduleList,
      applicationList: getLessonInfoResult.data?.applicationList,
    };
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getTemporalUser = async (phoneNum: string) => {
  let phone = phoneNum.replace(/-/g, '');
  if (phone.slice(0, 6) === '+82 10') {
    // "+82 1012341234"
    phone = '0' + phone.slice(4); // => "01012341234"
  }
  try {
    const getTemporalUserResult = await axios.get(
      `${API_URL}/api/user/temporalUser?phone=${phone}`,
    );
    return getTemporalUserResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const addTemporalUser = async (
  name: string,
  phoneNum: string,
  password?: string,
) => {
  let phone = phoneNum.replace(/-/g, '');
  if (phone.slice(0, 6) === '+82 10') {
    // "+82 1012341234"
    phone = '0' + phone.slice(4); // => "01012341234"
  }
  try {
    const addTemporalUserResult = await axios.post(
      `${API_URL}/api/user/temporalUser`,
      {
        user: {
          name,
          phone,
          password,
        },
      },
    );
    return addTemporalUserResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getAllUser = async (
  startIndex: number,
  numberOfItems: number = 15,
  branchId: 'all' | number,
  staffId?: number,
  keyword?: string,
  orderBy?: string,
  order?: string,
) => {
  if (startIndex < 1) {
    return console.error('startIndex는 1이상의 정수입니다');
  }
  if (numberOfItems < 1) {
    return console.error('numberOfItems는 1이상의 정수입니다');
  }
  let orderType: string = '102';
  if (orderBy && order) {
    const orderByList = ['', 'id', 'name'];
    orderType = orderByList.indexOf(orderBy) + '0' + (order === 'ASC' ? 1 : 2);
  }

  try {
    const getAllUserResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/user/allUserList?` +
        `orderType=${orderType}` +
        `&verified=all` +
        `&startIndex=${startIndex}` +
        `&numberOfItems=${numberOfItems}` +
        (staffId ? `&staffId=${staffId}` : '') +
        (keyword ? `&keyword=${keyword}` : ''),
    );
    return {
      userList: getAllUserResult.data.userList,
      size: getAllUserResult.data.size,
    };
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getUserInfo = async (userId: number) => {
  try {
    const getUserInfoResult = await axios.get(`${API_URL}/api/user/${userId}`);
    return getUserInfoResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateUserInfo = async (userId: number, userData: any) => {
  try {
    const updateUserInfoResult = await axios.put(
      `${API_URL}/api/user/${userId}`,
      {
        user: {
          name: userData?.name,
          gender: userData?.gender,
          phone: userData?.phone,
          adminName: userData?.adminName,
        },
      },
    );
    return updateUserInfoResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateUserMemo = async (userId: number, memo: string) => {
  try {
    const updateUserInfoResult = await axios.put(
      `${API_URL}/api/user/${userId}`,
      {
        user: {memo}
      },
    );
    return updateUserInfoResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const addLesson = async (lesson: any, scheduleList: Array<any>) => {
  try {
    const addLessonResult = await axios.post(`${API_URL}/api/lesson`, {
      lesson: {
        type: lesson?.type,
        userId: lesson?.userId,
        secondUserId: lesson?.secondUserId,
        thirdUserId: lesson?.thirdUserId,
        countPerWeek: lesson?.countPerWeek,
        day1: lesson?.day1,
        day2: lesson?.day2,
        day3: lesson?.day3,
        day4: lesson?.day4,
        timeSlot1: lesson?.timeSlot1,
        timeSlot2: lesson?.timeSlot2,
        timeSlot3: lesson?.timeSlot3,
        timeSlot4: lesson?.timeSlot4,
        slotLength1: lesson?.slotLength1,
        slotLength2: lesson?.slotLength2,
        slotLength3: lesson?.slotLength3,
        slotLength4: lesson?.slotLength4,
        firstDay: lesson?.firstDay,
        originalStartDate: lesson?.originalStartDate
          ? moment(lesson?.originalStartDate).locale('ko').format('yyyy-MM-DD')
          : moment(scheduleList[0]?.startAt).locale('ko').format('yyyy-MM-DD'),
        startDate: moment(scheduleList[0]?.startAt)
          .locale('ko')
          .format('yyyy-MM-DD'),
        endDate: moment(scheduleList[scheduleList.length - 1]?.startAt)
          .locale('ko')
          .format('yyyy-MM-DD'),
        changeDueDate: lesson?.changeDueDate
          ? moment(lesson?.changeDueDate).locale('ko').format('yyyy-MM-DD')
          : undefined,
        experience: lesson?.experience,
        lessonMemo: lesson?.lessonMemo,
        additionalInfo: lesson?.additionalInfo,
        leaveReason: lesson?.leaveReason,
        supplementaryClass: lesson?.supplementaryClass,
        lessonFee: lesson?.lessonFee,
        reservFee: lesson?.reservFee,
        reservFeeDate: lesson?.reservFeeDate
          ? moment(lesson?.reservFeeDate).locale('ko').format('yyyy-MM-DD')
          : null,
        paidFee: lesson?.paidFee,
        paidFeeDate: lesson?.paidFeeDate
          ? moment(lesson?.paidFeeDate).locale('ko').format('yyyy-MM-DD')
          : null,
        refundFee: lesson?.refundFee,
        refundFeeDate: lesson?.refundFeeDate
          ? moment(lesson?.refundFeeDate).locale('ko').format('yyyy-MM-DD')
          : undefined,
        lessonStatus: lesson?.lessonStatus,
        branchId: lesson?.branchId,
        staffId: lesson?.staffId,
        courtId: lesson?.courtId,
      },
      scheduleList: scheduleList,
    });
    return addLessonResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateLesson = async (
  lesson: any,
  scheduleList: Array<any>,
  supplementaryClass?: any,
  deleteScheduleTime?: string,
) => {
  const lastScheduleDate = new Date();
  lastScheduleDate.setTime(scheduleList[scheduleList.length - 1]?.startTime);

  const lessonObj:any = {
    startDate: lesson?.startDate
      ? moment(lesson?.startDate).locale('ko').format('yyyy-MM-DD')
      : undefined,
    endDate: lastScheduleDate
      ? moment(lastScheduleDate).locale('ko').format('yyyy-MM-DD')
      : undefined,
    changeDueDate: lesson?.changeDueDate
      ? moment(lesson?.changeDueDate).locale('ko').format('yyyy-MM-DD')
      : undefined,
    experience: lesson?.experience,
    lessonMemo: lesson?.lessonMemo,
    additionalInfo: lesson?.additionalInfo,
    leaveReason: lesson?.leaveReason,
    supplementaryClass: lesson?.supplementaryClass,
    lessonFee: lesson?.lessonFee,
    reservFee: lesson?.reservFee,
    reservFeeDate: lesson?.reservFeeDate
      ? moment(lesson?.reservFeeDate).locale('ko').format('yyyy-MM-DD')
      : undefined,
    paidFee: lesson?.paidFee,
    paidFeeDate: lesson?.paidFeeDate
      ? moment(lesson?.paidFeeDate).locale('ko').format('yyyy-MM-DD')
      : undefined,
    refundFee: lesson?.refundFee,
    refundFeeDate: lesson?.refundFeeDate
      ? moment(lesson?.refundFeeDate).locale('ko').format('yyyy-MM-DD')
      : undefined,
    lessonStatus: lesson?.lessonStatus,
    scheduleChangeCount: lesson?.scheduleChangeCount,
    branchId: lesson?.branchId,
    staffId: lesson?.staffId,
    courtId: lesson?.courtId,
  };
  if (lesson?.day1){
    lessonObj.day1 = lesson.day1;
    lessonObj.timeSlot1 = lesson.timeSlot1;
    lessonObj.slotLength1 = lesson.slotLength1;
  }
  if (lesson?.day2){
    lessonObj.day2 = lesson.day2;
    lessonObj.timeSlot2 = lesson.timeSlot2;
    lessonObj.slotLength2 = lesson.slotLength2;
  }
  if (lesson?.day3){
    lessonObj.day3 = lesson.day3;
    lessonObj.timeSlot3 = lesson.timeSlot3;
    lessonObj.slotLength3 = lesson.slotLength3;
  }

  try {
    const updateLessonResult = await axios.put(
      `${API_URL}/api/lesson/${lesson?.id}`,
      {
        lesson: lessonObj,
        scheduleList: supplementaryClass ? [supplementaryClass] : undefined,
        deleteScheduleTime, // yyyy-MM-DD 00:00 -> 00시 00분으로 설정하여 타겟이 된 날짜 뒤의 스케줄을 모두 지움
      },
    );
    return updateLessonResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const deleteLesson = async (lessonId: number) => {
  try {
    const deleteLessonResult = await axios.delete(
      `${API_URL}/api/lesson/${lessonId}`,
    );
    return deleteLessonResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const deleteSchedule = async (scheduleId: number) => {
  try {
    const deleteScheduleResult = await axios.delete(
      `${API_URL}/api/schedule/${scheduleId}`,
    );
    return deleteScheduleResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateLessonSimple = async (lessonId: number, obj: any) => {
  try {
    const updateLessonResult = await axios.put(
      `${API_URL}/api/lesson/${lessonId}/simple`,
      obj,
    );
    return updateLessonResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const moveSchedule = async (
  lessonId: number,
  schedule: any,
  duration: number,
) => {
  try {
    const moveScheduleResult = await axios.post(
      `${API_URL}/api/schedule/moveSchedule`,
      {
        schedule: {
          id: schedule?.id, // 이동 전 id
          movedFrom: schedule?.movedFrom, // 이동 전 id
          lessonId: lessonId,
          lessonCount: schedule?.lessonCount,
          lessonDate: schedule?.lessonDate, // 이동 후
          lessonTimeSlot: schedule?.lessonTimeSlot, // 이동 후
          startTime: schedule?.startTime,
          coverStaffId: schedule?.coverStaffId,
          scheduleMemo: schedule?.scheduleMemo,
          duration: duration,
          createdAt: new Date(),
        },
        lessonId: lessonId,
      },
    );
    return moveScheduleResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateSchedule = async (schedule: any) => {
  try {
    const updateScheduleResult = await axios.put(
      `${API_URL}/api/schedule/${schedule?.id}`,
      {
        schedule: {
          coverStaffId: schedule?.coverStaffId || null,
          cancelled: schedule?.cancelled,
          scheduleMemo: schedule?.scheduleMemo,
          moved: schedule?.moved,
          supplement: schedule?.supplement,
        },
      },
    );
    return updateScheduleResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getSupplementScheduleList = async (
  branchId: number,
  staffId?: number,
) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/staff/${staffId}/schedule/supplement`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getAllVideoList = async (
  branchId: number | 'all',
  orderBy: string,
  order: string,
  startIndex: number,
  numberOfItems: number,
  keyword: string,
  staffId?: number,
) => {
  const orderByList = ['', 'createdAt', 'requested', 'received'];
  const orderType =
    orderByList.indexOf(orderBy) + '0' + (order === 'ASC' ? 1 : 2);

  try {
    const getAllVideoListResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/video?` +
        `orderType=${orderType}` +
        `&verified=all` +
        `&startIndex=${startIndex || 1}` +
        `&numberOfItems=${numberOfItems || 15}` +
        (staffId ? `&staffId=${staffId}` : '') +
        (keyword ? `&keyword=${keyword}` : ''),
    );
    return {
      videoList: getAllVideoListResult.data.videoList,
      size: getAllVideoListResult.data.size,
    };
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getVideoInfo = async (videoId: number) => {
  try {
    const getVideoResult = await axios.get(`${API_URL}/api/video/${videoId}`);
    return getVideoResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateVideo = async (videoId: number, video: any) => {
  delete video.id;
  delete video.createdAt;

  try {
    const updateVideoResult = await axios.put(
      `${API_URL}/api/video/${videoId}`,
      {
        video: video,
      },
    );
    return updateVideoResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getAllScheduleList = async (
  branchId: number,
  startTime: number,
  startAt: string,
  endTime: number,
  endAt: string,
  staffId?: number,
  type?: string,
  keyword?: string,
) => {
  try {
    const getAllScheduleListResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/schedule/calendar?` +
        `startTime=${startTime}` +
        `&startAt=${startAt}` +
        `&endTime=${endTime}` +
        `&endAt=${endAt}` +
        //(staffId ? `&staffId=${staffId}` : '') +
        (type ? `&lType=${type}` : '') +
        (keyword ? `&keyword=${keyword}` : ''),
    );

    const returnScheduleList = getAllScheduleListResult.data?.map((el: any) => {
      return {
        id: el?.id,
        firstName: el?.adminName1 || el?.firstName,
        firstPhone: el?.firstPhone,
        secondName: el?.adminName2 || el?.secondName,
        secondPhone: el?.secondPhone,
        thirdName: el?.adminName3 || el?.thirdName,
        thirdPhone: el?.thirdPhone,
        title: el?.title,
        start: el?.startAt || el?.startTime,
        end: el?.endAt || el?.endTime,
        backgroundColor: el?.backgroundColor,
        borderColor: el?.borderColor,
        lessonId: el?.lessonId,
        scheduleMemo: el?.scheduleMemo,
        lessonCount: el?.lessonCount,
        lessonMemo: el?.lessonMemo,
        lessonStartDate: el?.startDate,
        lessonEndDate: el?.endDate,
        staffId: el?.staffId,
        cancelled: el?.cancelled,
        lessonType: el?.lType,
        lessonStatus: el?.lessonStatus,
        moved: el?.moved,
        movedFrom: el?.movedFrom,
        coverStaffId: el?.coverStaffId,
        startAt: el?.startAt || el?.startTime,
        endAt: el?.endAt || el?.endTime,
        day1: el?.day1,
        day2: el?.day2,
        day3: el?.day3,
        day4: el?.day4,
        noExtend: el?.noExtend,
      };
    });

    return {
      scheduleList: returnScheduleList,
      size: returnScheduleList?.length,
    };
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getWaitingList = async (
  branchId: number,
  type: string,
  status: string,
  startIndex: number,
  numberOfItems: number,
  keyword?: string,
) => {
  try {
    const getWaitingListResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/waiting/allWaitingList?` +
        `orderType=101` +
        (type === 'all' ? '' : `&waitingType=${type}`) +
        `&waitingStatus=${status}` +
        `&startIndex=${startIndex || 1}` +
        `&numberOfItems=${numberOfItems || 15}` +
        (keyword ? `&keyword=${keyword}` : ''),
    );
    return getWaitingListResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getWaitingInfo = async (waitingId: number) => {
  try {
    const getWaitingInfoResult = await axios.get(
      `${API_URL}/api/waiting/${waitingId}`,
    );
    return getWaitingInfoResult.data[0];
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createWaiting = async (waitingData: any) => {
  try {
    const postResult = await axios.post(`${API_URL}/api/waiting`, {
      waiting: {
        type: waitingData.type,
        userName: waitingData.userName,
        phoneKakao: waitingData.phoneKakao,
        secondUserName: waitingData.secondUserName,
        secondPhoneKakao: waitingData.secondPhoneKakao,
        thirdUserName: waitingData.thirdUserName,
        thirdPhoneKakao: waitingData.thirdPhoneKakao,
        countPerWeek: waitingData.countPerWeek,
        dayJson: JSON.stringify(waitingData.dayJson),
        timeSlotJson: JSON.stringify(waitingData.timeSlotJson),
        experience: waitingData.experience,
        priority: waitingData.priority,
        waitingMemo: waitingData.waitingMemo,
        branchId: waitingData.branchId,
      },
    });
    return postResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateWaiting = async (waitingId: number, waitingData: any) => {
  try {
    const newWaitingData = waitingData;
    newWaitingData.dayJson = JSON.stringify(waitingData.dayJson);
    newWaitingData.timeSlotJson = JSON.stringify(waitingData.timeSlotJson);
    delete newWaitingData.createdAt;
    delete newWaitingData.id;
    const putResult = await axios.put(`${API_URL}/api/waiting/${waitingId}`, {
      waiting: newWaitingData,
    });
    console.log(putResult.data);
    return putResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getAllCommentList = async (
  category: string,
  startIndex: number,
  numberOfItems: number,
  keyword?: string,
) => {
  try {
    const getAllCommentListResult = await axios.get(
      `${API_URL}/api/comment/allCommentList?` +
        `orderType=101` +
        (keyword ? `&keyword=${keyword}` : '') +
        (category === 'all' ? '' : `&category=${category}`) +
        `&startIndex=${startIndex}` +
        `&numberOfItems=${numberOfItems}`,
    );
    return getAllCommentListResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createComment = async (commentData: any) => {
  try {
    const postResult = await axios.post(`${API_URL}/api/comment`, {
      comment: {
        category1: commentData?.category1,
        category2: commentData?.category2 || null,
        comment: commentData?.comment,
      },
    });
    return postResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateComment = async (commentId: number, commentData: any) => {
  try {
    const putResult = await axios.put(`${API_URL}/api/comment/${commentId}`, {
      comment: {
        category1: commentData?.category1,
        category2: commentData?.category2 || null,
        comment: commentData?.comment,
      },
    });
    return putResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const deleteComment = async (commentId: number) => {
  try {
    const deleteResult = await axios.delete(
      `${API_URL}/api/comment/${commentId}`,
    );
    return deleteResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getAllReferenceList = async (
  category: string,
  startIndex: number,
  numberOfItems: number,
  keyword?: string,
) => {
  try {
    const getAllReferenceListResult = await axios.get(
      `${API_URL}/api/commentVideo/allCommentVideoList?` +
        (keyword ? `&keyword=${keyword}` : '') +
        (category === 'all' ? '' : `&category=${category}`) +
        `&startIndex=${startIndex}` +
        `&numberOfItems=${numberOfItems}`,
    );
    return {
      referenceList: getAllReferenceListResult.data?.commentVideoList,
      size: getAllReferenceListResult.data?.size,
    };
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getReferenceInfo = async (referenceId: number) => {
  try {
    const getReferenceInfoResult = await axios.get(
      `${API_URL}/api/commentVideo/${referenceId}`,
    );
    return getReferenceInfoResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createReference = async (
  referenceData: any,
  commentList: any[],
) => {
  try {
    const postResult = await axios.post(`${API_URL}/api/commentVideo`, {
      commentVideo: {
        id: referenceData?.id,
        title: referenceData?.title,
        youtubeId: referenceData?.youtubeId,
        tags: referenceData?.tags,
        startTime: referenceData?.startTime,
        endTime: referenceData?.endTime,
      },
      commentList: commentList,
    });
    return postResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateReference = async (
  commentVideoId: number,
  referenceData: any,
  prevCommentList?: Array<any>,
) => {
  try {
    const addCommentList: Array<any> = [];
    const deleteCommentList: Array<any> = [];

    referenceData?.commentList?.forEach((v: any) => {
      if (prevCommentList?.indexOf(v) === -1) {
        //  지금은 있는데, 이전엔 없었다면 insert
        addCommentList.push(v);
      }
    });

    prevCommentList?.forEach((v: any) => {
      if (referenceData?.commentList.indexOf(v) === -1) {
        //   이전엔 있었는데, 지금은 없으므로 delete
        deleteCommentList.push(v);
      }
    });

    referenceData.addCommentList = addCommentList;
    referenceData.deleteCommentList = deleteCommentList;
    delete referenceData?.commentList;

    const putResult = await axios.put(
      `${API_URL}/api/commentVideo/${commentVideoId}`,
      {
        commentVideo: {
          title: referenceData?.title,
          youtubeId: referenceData?.youtubeId,
          tags: referenceData?.tags,
          startTime: referenceData?.startTime,
          endTime: referenceData?.endTime,
        },
        addCommentList: addCommentList,
        deleteCommentList: deleteCommentList,
      },
    );
    return putResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const deleteReferenceInfo = async (commentVideoId: number) => {
  try {
    const deleteResult = await axios.delete(
      `${API_URL}/api/commentVideo/${commentVideoId}`,
    );
    return deleteResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getAllNews = async (
  orderBy: string,
  order: string,
  startIndex: number,
  numberOfItems: number = 15,
  keyword?: string,
) => {
  let orderType: string = '102';
  if (orderBy && order) {
    const orderByList = ['', 'id'];
    orderType = orderByList.indexOf(orderBy) + '0' + (order === 'ASC' ? 1 : 2);
  }

  try {
    const getAllNewsResult = await axios.get(
      `${API_URL}/api/news/allNewsList?` +
        `orderType=${orderType}` +
        `&startIndex=${startIndex}` +
        `&numberOfItems=${numberOfItems}` +
        (keyword ? `&keyword=${keyword}` : ''),
    );
    return getAllNewsResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getNewsInfo = async (newsId: number) => {
  try {
    const getNewsInfoResult = await axios.get(`${API_URL}/api/news/${newsId}`);
    return getNewsInfoResult.data[0];
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createNews = async (newsData: any) => {
  try {
    const postResult = await axios.post(`${API_URL}/api/news`, {
      news: {
        title: newsData?.title,
        content: newsData?.content,
      },
    });
    return postResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateNews = async (newsId: number, newsData: any) => {
  try {
    const putResult = await axios.put(`${API_URL}/api/news/${newsId}`, {
      news: {
        title: newsData?.title,
        content: newsData?.content,
      },
    });
    console.log(putResult.data);
    return putResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getDashBoardTotalUser = async () => {
  try {
    const getResult = await axios.get(`${API_URL}/api/dashboard/totalUser`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getDashBoardWeeklyUser = async () => {
  try {
    const getResult = await axios.get(`${API_URL}/api/dashboard/weeklyUser`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getDashBoardTotalVideo = async () => {
  try {
    const getResult = await axios.get(`${API_URL}/api/dashboard/totalVideo`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getDashBoardTennisBay = async (type: string, branchId: number) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/dashboard/branch/${branchId}/tennisBay?type=${type}`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getDashBoardTennisBayChart = async (
  type: string,
  branchId: number,
) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/dashboard/branch/${branchId}/tennisBayChart?type=${type}`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getDashBoardTennisBayLesson = async (branchId: number) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/dashboard/branch/${branchId}/tennisBayLesson`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getDashBoardWaitingLesson = async (branchId: number) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/dashboard/branch/${branchId}/waitingLesson`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getLessonPayHistorys = async (lessonId: number) => {
  try {
    const getResult = await axios.get(
      `${TENNIS_APP_API_URL}/api/lesson/${lessonId}/pay/history`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const refund = async (
  lessonId: number,
  merchant_uid: number,
  amount: number,
  reason?: string,
) => {
  try {
    const refundResult = await axios.post(`${TENNIS_APP_API_URL}/api/refund`, {
      merchant_uid: merchant_uid,
      targetId: lessonId,
      amount: amount,
      reason: reason,
    });
    return refundResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const login = async (email?: string, password?: string) => {
  try {
    const loginResult = await axios.post(`${API_URL}/api/login`, {
      user: { email: email, password: password },
    });
    if (loginResult.data?.token) {
      axios.defaults.headers.common[
        'authorization'
      ] = `Bearer ${loginResult.data?.token}`;
    }
    return loginResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const logoutApi = async () => {
  try {
    const logoutResult = await axios.get(`${API_URL}/api/logout`);
    return logoutResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const uploadApplicationPDF = async (pdfFile: FormData) => {
  try {
    const uploadResult = await axios.post(
      `${API_URL}/api/application/pdf`,
      pdfFile,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return uploadResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const addApplication = async (application: any) => {
  try {
    const addResult = await axios.post(`${API_URL}/api/application`, {
      application: application,
    });
    return addResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getBranchInfo = async (branchId: number) => {
  try {
    const getResult = await axios.get(`${API_URL}/api/branch/${branchId}`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getMyStaffInfo = async () => {
  try {
    const getResult = await axios.get(`${API_URL}/api/staff/me`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getMySalaryList = async (year: string | number) => {
  try {
    const getResult = await axios.get(`${API_URL}/api/salary/year/${year}/me`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getPayroll = async (staffId: number, yearMonth: string) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/payroll/staff/${staffId}/yearMonth/${yearMonth}`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateMyUserInfo = async (
  password: string,
  newPassword: string,
) => {
  try {
    const putResult = await axios.put(`${API_URL}/api/user/me`, {
      password,
      newPassword,
    });
    return putResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateBranch = async (branch: any, branchId: number) => {
  try {
    const putResult = await axios.put(`${API_URL}/api/branch/${branchId}`, {
      branch: branch,
    });
    return putResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createTodayshot = async (scheduleId: number, url: string) => {
  try {
    const result = await axios.post(`${WORKER_URL}/video/3970/pose`, {
      scheduleId,
      url,
    });
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getPriceList = async (branchId: number) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/price`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updatePrice = async (priceId: number, price: number) => {
  try {
    const putResult = await axios.put(`${API_URL}/api/price/${priceId}`, {
      price: price,
    });
    return putResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createPrice = async (price: any) => {
  try {
    const postResult = await axios.post(`${API_URL}/api/price`, {
      price: price,
    });
    return postResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getStaffList = async (
  branchId: number,
  status?: 'all' | 'work' | 'leave',
  keyword?: string,
) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/staff?status=${status}${
        keyword ? `&keyword=${keyword}` : ''
      }`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getExpectedScheduleList = async (
  branchId: number,
  staffId?: number,
) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/schedule/calendar/expected?staffId=${staffId}`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getStaffInfo = async (staffId: number) => {
  try {
    const getResult = await axios.get(`${API_URL}/api/staff/${staffId}`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createStaff = async (staff: any) => {
  try {
    const postResult = await axios.post(`${API_URL}/api/staff`, {
      staff: staff,
    });
    return postResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateStaff = async (staffId: number, staff: any) => {
  try {
    const putResult = await axios.put(`${API_URL}/api/staff/${staffId}`, {
      staff: staff,
    });
    return putResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const uploadContractPDF = async (pdfFile: FormData) => {
  try {
    const uploadResult = await axios.post(
      `${API_URL}/api/contract/pdf`,
      pdfFile,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return uploadResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const checkScheduleCrash = async (
  branchId: number,
  lesson: any,
  scheduleList: Array<any>,
) => {
  const lastScheduleDate = new Date();
  lastScheduleDate.setTime(scheduleList[scheduleList.length - 1]?.startTime);

  try {
    const checkResult = await axios.post(
      `${API_URL}/api/branch/${branchId}/schedule/check/crash`,
      {
        lesson: lesson,
        scheduleList: scheduleList,
      },
    );
    return checkResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getContractList = async (staffId: number) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/staff/${staffId}/contract`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createContract = async (contract: any) => {
  try {
    const postResult = await axios.post(`${API_URL}/api/contract`, {
      contract: contract,
    });
    return postResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateContract = async (contractId: number, contract: any) => {
  try {
    const putResult = await axios.put(`${API_URL}/api/contract/${contractId}`, {
      contract: contract,
    });
    return putResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getLessonEndThisWeekUserList = async (
  branchId: number,
  staffId: number | 'all',
) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/dashboard/branch/${branchId}/staff/${staffId}`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getTodayScheduleList = async (
  branchId: number,
  staffId: number | 'all',
) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/staff/${staffId}/schedule/today`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getSalaryList = async (
  branchId: number,
  yearMonth: string,
  staffId: 'all' | number,
  settled?: string,
) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/staff/${staffId}/salary/yearMonth/${yearMonth}?settled=${settled}`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateSalary = async (salaryId: number, salary: any) => {
  try {
    const putResult = await axios.put(`${API_URL}/api/salary/${salaryId}`, {
      salary: salary,
    });
    return putResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getBranchList = async () => {
  try {
    const getResult = await axios.get(`${API_URL}/api/allBranch`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getMasterAdminBranchList = async (staffId: number) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/masterAdmin/staff/${staffId}/branch`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const checkStaffWithUserId = async (userId: number) => {
  try {
    const getResult = await axios.get(`${API_URL}/api/user/${userId}/staff`);
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getCoverScheduleList = async (
  branchId: number,
  staffId: number,
) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/staff/${staffId}/schedule/calendar/cover`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createTimeSlot = async (
  branchId: number,
  staffId: number,
  dayOfWeek: number,
  startTimeSlot: string,
  endTimeSlot: string,
) => {
  try {
    const postResult = await axios.post(
      `${API_URL}/api/branch/${branchId}/staff/${staffId}/timeSlot`,
      {
        dayOfWeek: dayOfWeek,
        startTime: startTimeSlot,
        endTime: endTimeSlot,
      },
    );
    return postResult;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const deleteTimeSlot = async (timeSlotId: number) => {
  try {
    const deleteResult = await axios.delete(
      `${API_URL}/api/timeSlot/${timeSlotId}`,
    );
    return deleteResult;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getSlotListOfCoach = async (branchId: number, staffId: number) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/staff/${staffId}/timeSlot`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getBlackListOfNewSchedule = async (
  branchId: number,
  lessonId?: number,
) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/lesson/${lessonId}/schedule/blacklist`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const generateSchedule = async (
  branchId: number,
  staffId: number | undefined,
  daySlotList: Array<any>,
  slotDuration: number, // 슬롯 길이 ex) 20분
  endDate: string | Date,
  generateCount: number,
) => {
  try {
    const postResult = await axios.post(
      `${API_URL}/api/branch/${branchId}/staff/${staffId}/schedule/generate`,
      {
        daySlotList,
        slotDuration,
        endDate,
        generateCount,
      },
    );
    return postResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getSideVideoList = async (
  branchId: number,
  orderBy: string,
  order: string,
  startIndex: number,
  numberOfItems: number,
  position: string,
  courtId?: number,
) => {
  const orderByList = ['', 'createdAt', 'startAt'];
  const orderType =
    orderByList.indexOf(orderBy) + '0' + (order === 'ASC' ? 1 : 2);
  try {
    const getAllCommentListResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/sideVideo?` +
        `orderType=${orderType}` +
        (courtId ? `&courtId=${courtId}` : '') +
        (position === 'all' ? '' : `&position=${position}`) +
        `&startIndex=${startIndex}` +
        `&numberOfItems=${numberOfItems}`,
    );
    return getAllCommentListResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getSideVideo = async (sideVideoId: number) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/sideVideo/${sideVideoId}`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getOpSlotList = async (branchId: number, staffId?: number) => {
  try {
    const getResult = await axios.get(
      `${API_URL}/api/branch/${branchId}/staff/${staffId}/opSlot`,
    );
    return getResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createOpSlot = async (
  branchId: number,
  staffId: number,
  startAt: string,
  endAt: string,
  type?: 'consult',
  memo?: string,
) => {
  try {
    const postResult = await axios.post(`${API_URL}/api/opSlot`, {
      branchId: branchId,
      staffId: staffId,
      startAt: startAt,
      endAt: endAt,
      type: type,
      memo: memo,
    });
    return postResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateOpSlot = async (opSlotId: number, memo: string) => {
  try {
    const putResult = await axios.put(`${API_URL}/api/opSlot/${opSlotId}`, {
      memo: memo,
    });
    return putResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const deleteOpSlot = async (opSlotId: number) => {
  try {
    const deleteResult = await axios.delete(
      `${API_URL}/api/opSlot/${opSlotId}`,
    );
    return deleteResult;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const guestLogin = async (guestId?: string, password?: string) => {
  try {
    const postResult = await axios.post(`${API_URL}/api/guest/login`, {
      guest: { guestId: guestId, password: password },
    });
    if (postResult.data?.token) {
      axios.defaults.headers.common[
        'authorization'
      ] = `Bearer ${postResult.data?.token}`;
    }
    return postResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const validateUser = async (password: string) => {
  try {
    const result = await axios.put(`${API_URL}/api/user/validate`, {
      password,
    });
    return result?.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getUploadUrl = async (urlpath: string) => {
  try {
    const result = await axios.post(`${API_URL}/api/tempFeedback/upload/url`, {
      urlpath: urlpath,
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const uploadToBucket = async (preSignedUrl: string, file: File) => {
  try {
    const option = {
      method: 'PUT',
      body: file,
    };

    let result: any = await fetch(preSignedUrl, option);

    const s3Url = preSignedUrl.split('?')[0];
    if (!result) result = {};
    if (!result.data)
      result.data = {
        url: s3Url,
      };

    return result;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const createTempFeedback = async (tempFeedback: any) => {
  try {
    const result = await axios.post(`${API_URL}/api/newTempFeedback`, {
      tempFeedback: tempFeedback,
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getTempFeedback = async (hash: string) => {
  try {
    const result = await axios.get(`${API_URL}/api/temp/hash/${hash}`);
    return result?.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createLog = async (log: any) => {
  try {
    const result = await axios.post(`${API_URL}/api/temp/log`, {
      log,
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const sendLms = async (phone: string, content: string) => {
  try {
    const result = await axios.post(`${API_URL}/api/temp/lms`, {
      phone,
      content,
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const noExtendLesson = async (lessonId: number) => {
  try {
    const result = await axios.put(`${API_URL}/api/lesson/noExtend`, {
      lessonId,
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getTrainingSchedules = async () => {
  try {
    const result = await axios.get(`${API_URL}/api/temp/slots`);
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const deleteReserveSlot = async (
  day: number,
  name: string,
  phone: string,
  time: string,
) => {
  try {
    const result = await axios.delete(`${API_URL}/api/temp/reserve`, {
      data: {
        day,
        name,
        phone,
        time,
      },
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const SENDIA_URL = 'https://sendia.io';
export const getSignalList = async () => {
  try {
    const result = await axios.get(`${SENDIA_URL}/api/tennis/signal`);
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const pushSignal = async (hash: string, signal: string) => {
  try {
    const result = await axios.post(`${SENDIA_URL}/api/tennis/signal`, {
      hash,
      signal,
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSchedulePlaceList = async (branchId: number) => {
  try {
    const result = await axios.get(
      `${API_URL}/api/branch/${branchId}/schedulePlace`,
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const updateSchedulePlace = async (
  schedulePlaceId: number,
  obj: any,
) => {
  try {
    const result = await axios.put(
      `${API_URL}/api/schedulePlace/${schedulePlaceId}`,
      { schedulePlace: obj },
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getVtgRawVideoClips = async (rawVideoId: number) => {
  try {
    const result = await axios.get(
      `${API_URL}/api/vtg/rawVideoClip?rawVideoId=${rawVideoId}`,
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const updateVtgRawVideoClip = async (clipId: number, obj: any) => {
  try {
    const result = await axios.put(
      `${API_URL}/api/vtg/rawVideoClip/${clipId}`,
      { vtgRawVideoClip: obj },
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getVtgSchedule = async (scheduleId: number) => {
  try {
    const result = await axios.get(`${API_URL}/api/vtg/schedule/${scheduleId}`);
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getVtgRawVideoList = async (scheduleId: number) => {
  try {
    const result = await axios.get(
      `${API_URL}/api/vtg/schedule/${scheduleId}/rawVideo`,
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const makeRawVideoStat = async (scheduleId: number) => {
  try {
    const result = await axios.post(
      `${API_URL}/api/vtg/schedule/${scheduleId}/calculate`,
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getVtgUsers = async () => {
  try {
    const result = await axios.get(`${API_URL}/api/vtg/user`);
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getVtgUserSolutions = async (userId: number) => {
  try {
    const result = await axios.get(
      `${API_URL}/api/vtg/user/${userId}/solution`,
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getVtgSolutions = async () => {
  try {
    const result = await axios.get(`${API_URL}/api/vtg/solution`);
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getVtgSchedules = async (userId: number) => {
  try {
    const result = await axios.get(
      `${API_URL}/api/vtg/user/${userId}/schedule`,
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const createVtgUserSolution = async (obj: any) => {
  try {
    const result = await axios.post(`${API_URL}/api/vtg/userSolution`, {
      userSolution: obj,
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

/*
        ticketStatus,
        page,
        15,
        keyword
      );
*/

export const getVtgTicketList = async (
  ticketStatus: string | null,
  page: number,
  size: number,
  keyword?: string,
) => {
  try {
    const result = await axios.get(
      // `${API_URL}/api/vtg/ticket?`
      `${API_URL}/api/vtg/ticket?` +
        (page ? `page=${page}` : '') +
        (ticketStatus ? `&status=${ticketStatus}` : '') +
        (size ? `&size=${size}` : '') +
        (keyword ? `&keyword=${keyword}` : ''),
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getVtgTicketInfo = async (ticketId: number) => {
  try {
    const result = await axios.get(`${API_URL}/api/vtg/ticket/${ticketId}`);
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const createVtgTicket = async (obj: any) => {
  try {
    const result = await axios.post(`${API_URL}/api/vtg/ticket`, {
      ticket: obj,
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const updateVtgTicket = async (obj: any) => {
  try {
    const result = await axios.put(`${API_URL}/api/vtg/ticket/${obj.id}`, {
      ticket: obj,
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const deleteVtgTicket = async (id: number) => {
  try {
    const result = await axios.delete(`${API_URL}/api/vtg/ticket/${id}`);
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getNoShowLogList = async () => {
  try {
    const result = await axios.get(`${API_URL}/api/vtg/noShowLog`);
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getPayList = async (
  status: string | null,
  page: number,
  size: number,
  keyword?: string,
) => {
  try {
    const result = await axios.get(
      `${API_URL}/api/pay?` +
        (page ? `page=${page}` : '') +
        (status ? `&status=${status}` : '') +
        (size ? `&size=${size}` : '') +
        (keyword ? `&keyword=${keyword}` : ''),
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getCandidateSchedules = async (sidevideoId: number) => {
  try {
    const result = await axios.get(
      `${API_URL}/api/sidevideo/${sidevideoId}/candidate`,
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createLessonFeeLog = async (obj: any) => {
  try {
    const result = await axios.post(
      `${API_URL}/api/lesson/${obj.lessonId}/feeLog`,
      { feeLog: obj },
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getLessonUserList = async (lessonId: number) => {
  try {
    const result = await axios.get(`${API_URL}/api/lesson/${lessonId}/user`);
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const bindSideVideo = async (
  scheduleId: number,
  sidevideoId: number,
) => {
  try {
    const result = await axios.put(
      `${API_URL}/api/sidevideo/${sidevideoId}/bind`,
      { scheduleId },
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const confirmLessonFeeLog = async (feeLogId: number) => {
  try {
    const result = await axios.put(`${API_URL}/api/feeLog/${feeLogId}`, {
      feeLog: { isSaved: true },
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getFeeLogList = async (
  lessonId: number,
  stagedId: number | undefined,
) => {
  try {
    const result = await axios.get(
      `${API_URL}/api/lesson/${lessonId}/feeLog${
        stagedId ? '?stagedId=' + stagedId : ''
      }`,
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const deleteLessonFeeLog = async (feeLogId: number) => {
  try {
    const result = await axios.delete(`${API_URL}/api/feeLog/${feeLogId}`, {});
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const createNextLesson = async (
  branchId: number,
  holidays: Array<any>,
  transList: Array<any>,
) => {
  try {
    const result = await axios.post(
      `${API_URL}/api/branch/${branchId}}/lesson/nextMonth`,
      { holidays, transList },
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getNextLessonLogList = async (branchId: number) => {
  try {
    const result = await axios.get(
      `${API_URL}/api/branch/${branchId}/lessonLog`,
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const sendPayLesson = async (
  id: number,
  type: string,
  message: string,
) => {
  try {
    const result = await axios.post(`${API_URL}/api/message/lesson/pay`, {
      id,
      type,
      message,
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const checkVideoTemp = async (obj: any) => {
  try {
    const result = await axios.post(`${API_URL}/api/temp/checkVideoTemp`, obj);
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createVideoTempMemo = async (obj: any) => {
  try {
    const result = await axios.post(
      `${API_URL}/api/temp/videoTemp/${obj.videoTempId}/memo`,
      { videoTempMemo: obj },
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getBallTrackInfo = async (videoTempId: number) => {
  try {
    const result = await axios.get(
      `${API_URL}/api/temp/videoTemp/${videoTempId}/ballTrack`,
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const checkVtgDupl = async (
  date: string,
  slotTime: string,
  scheduleId: number,
) => {
  try {
    const result = await axios.get(
      `${API_URL}/api/vtg/checkDupl?date=${date}&slotTime=${slotTime}&scheduleId=${scheduleId}`,
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getBusinessHourList = async (branchId: number, hourType='vtg') => {
  try {
    const result = await axios.get(
      `${API_URL}/api/branch/${branchId}/businessHour?type=${hourType}`,
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const createBusinessHour = async (branchId: number, obj: any) => {
  try {
    const result = await axios.post(
      `${API_URL}/api/branch/${branchId}/businessHour`,
      { businessHour: obj },
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateBusinessHour = async (obj: any) => {
  try {
    const result = await axios.put(`${API_URL}/api/businessHour/${obj.id}`, {
      businessHour: obj,
    });
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getAllVideoTempList = async (
  order: string,
  startIndex: number,
  numberOfItems: number,
  keyword: string,
  staffId?: number,
) => {
  try {
    const getAllVideoTempListResult = await axios.get(
      `${API_URL}/api/temp/videoTemp/list?` +
        `order=${order}` +
        `&startIndex=${startIndex || 1}` +
        `&numberOfItems=${numberOfItems || 15}` +
        (staffId ? `&staffId=${staffId}` : '') +
        (keyword ? `&keyword=${keyword}` : ''),
    );
    return {
      videoList: getAllVideoTempListResult.data.videoTempList,
      size: getAllVideoTempListResult.data.size,
    };
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getVideoTempInfo = async (videoTempId: number) => {
  try {
    const getVideoResult = await axios.get(`${API_URL}/api/temp/videoTemp/${videoTempId}`);
    return getVideoResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getVideoTempMemoList = async (videoTempId: number) => {
  try {
    const commentResult = await axios.get(
      `${API_URL}/api/temp/videoTemp/${videoTempId}/memo`);
    return commentResult?.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const getServeSetSummary = async (startAt:string, endAt:string, userId: number) => {
  try {
    const summaryResult = await axios.get(
      `${API_URL}/api/temp/videoTemp/summary?userId=${userId}&startAt=${startAt}&endAt=${endAt}`);
    return summaryResult?.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const resetUserPassword = async (userId: number) => {
  try {
    const result = await axios.put(`${API_URL}/api/user/${userId}/resetPassword`, {
    });
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const requestEmail = async (patient:any, to:string, subject:string, body:string) => {
  try {
    const result = await axios.post(`${API_URL}/api/email`, {
      patient,
      to,
      subject,
      body,
    });
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateAnalysisResult = async (patientId: number|undefined) => {

  try {
    const result = await axios.post(`${API_URL}/api/analysis`, {patientId});
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};


export const getDashboard = async () => {
  try {
    const result = await axios.get(`${API_URL}/api/dashboard`);
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const updateMyPassword = async (password: string, newPassword: string) => {
  try {
    const result = await axios.put(
      `${API_URL}/api/user/me/updatePassword`,
      { password, newPassword },
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  } 
}


export const updateMyPageInfo = async (userId: number, userObj: any) => {
  try {
    const updateUserInfoResult = await axios.put(
      `${API_URL}/api/user/${userId}`,
      {
        user: userObj
      },
    );
    return updateUserInfoResult.data;
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};
