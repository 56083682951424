import { FC } from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

const Error404: FC = () => {
  return (
    <>
      {/* begin::Title */}
      <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">페이지를 찾을 수 없습니다.</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className="fw-semibold fs-6 text-gray-500 mb-7">
        죄송합니다. 페이지를 찾을수 없습니다.<br/>
        존재하지 않는 주소를 입력하셨거나 <br/>
        요청하신 페이지의 주소가 변경/삭제되어 찾을 수 없습니다.
      </div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className="mb-3">
        <img
          // src={toAbsoluteUrl('/media/auth/404-error.png')}
          src={toAbsoluteUrl('/media/auth/404-error-dk.png')}
          className="mw-100 mh-300px theme-light-show"
          alt=""
        />
        <img
          //src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
          src={toAbsoluteUrl('/media/auth/404-error-dk.png')}
          className="mw-100 mh-300px theme-dark-show"
          alt=""
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className="mb-0">
        <Link to="/" className="btn btn-sm btn-primary">
          <b>홈으로 가기</b>
        </Link>
      </div>
      {/* end::Link */}
    </>
  );
};

export { Error404 };
