import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { LayoutSplashScreen } from '../../../../_metronic/layout/core';
import { AuthModel, UserModel } from './_models';
import * as authHelper from './AuthHelpers';
import { WithChildren } from '../../../../_metronic/helpers';
import {
  login,
  logoutApi,
} from '../../../../apis';
import axios from 'axios';
import { useLang, setLanguage } from '../../../../_metronic/i18n/Metronici18n';
import { useIntl } from 'react-intl'; 

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: any | UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  currentBranch: any | undefined;
  setCurrentBranch: Dispatch<SetStateAction<any | undefined>>;
  currentStaff: any | undefined;
  setCurrentStaff: Dispatch<SetStateAction<any | undefined>>;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  currentBranch: undefined,
  setCurrentBranch: () => {},
  currentStaff: undefined,
  setCurrentStaff: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  const [currentBranch, setCurrentBranch] = useState<any | undefined>();
  const [currentStaff, setCurrentStaff] = useState<any | undefined>();
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
    setCurrentBranch(undefined);
    setCurrentStaff(undefined);
    axios.defaults.headers.common['authorization'] = '';
    logoutApi();
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        currentBranch,
        setCurrentBranch,
        currentStaff,
        setCurrentStaff,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser} =
    useAuth();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const intl = useIntl();


  useEffect(() => {
    (async function () {
      if (auth && auth?.api_token) {
        const loginResult = await login();
        if (!loginResult?.error) {
          await setCurrentUser(loginResult?.user);
          await setShowSplashScreen(false);
          
          if (loginResult?.user?.language) {

            const currentLang = intl.formatMessage({id:"CURRENT_LANG"})
            console.log("loginResult?.user?.language", loginResult?.user?.language);
            console.log("currentLang", currentLang);  

            if (loginResult?.user?.language !==  currentLang) {
              setLanguage(loginResult?.user?.language);
            }
          }
        }
      } else {
        logout();
        setShowSplashScreen(false);
      }
    })();
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
