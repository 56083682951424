/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { useAuth } from '../core/Auth';
import {
  getMyInfo,
  guestLogin,
  login,
} from '../../../../apis';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
// import {useNavigate} from 'react-router-dom';

const loginSchema = Yup.object().shape({
  email: Yup.string().required(
    'Enter the admin email address',
  ),
  password: Yup.string()
    .min(3, 'at least 3 characters are required.')
    .required('Enter the password'),
});

const initialValues = {
  email: '',
  password: '',
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const { saveAuth, setCurrentUser, setCurrentStaff, setCurrentBranch } =
    useAuth();
  //const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const result = await login(values.email, values.password);

        if (result?.loginMessage) {
          saveAuth(undefined);
          setStatus(result?.loginMessage);
          setSubmitting(false);
          setLoading(false);
          return;
        }

        await setCurrentUser(result?.userData);
        const myInfo = await getMyInfo();
        if (myInfo) {
          await setCurrentUser(myInfo?.user);
        }
        await saveAuth({
          api_token: result?.token,
        });
        await setLoading(false);

        window.location.href = "/dashboard";

        // if (result?.loginMessage) {
        //   saveAuth(undefined);
        //   setStatus(result?.loginMessage);
        //   setSubmitting(false);
        //   setLoading(false);
        // }
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus('The login details are incorrect');
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const guestSignIn = async () => {
    setLoading2(true);
    try {
      const result = await guestLogin(
        formik.values.email,
        formik.values.password,
      );
      await setCurrentUser(result?.guestData);
      setCurrentStaff({ staffType: 'guest' });
      await saveAuth({
        api_token: result?.token,
        guest: true,
      });
      await setLoading2(false);
      if (result?.loginMessage) {
        saveAuth(undefined);
        setLoading2(false);
      }
    } catch (error) {
      console.error(error);
      saveAuth(undefined);

      setLoading2(false);
    }
  };

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-11 d-flex flex-center">
        <img
          alt="Logo"
          // src = {"https://cdn-icons-png.flaticon.com/512/9980/9980424.png"}
          src={toAbsoluteUrl('/media/logos/hlogo.png')}
          className="h-50px rounded"
        />
        <h1 className="text-dark fw-bolder ms-3 mt-3">HiDP System</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">
            {formik.status === 'NoUserIsFound'
              ? 'Not rigistered account'
              : formik.status === 'WrongPassword'
              ? 'Wrong password. Please check again'
              : formik.status}
          </div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <label className="form-label fs-6 fw-bolder text-dark">Email</label>
        <input
          placeholder="user@email.com"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            },
          )}
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-3">
        <label className="form-label fw-bolder text-dark fs-6">Password</label>
        <input
          type="password"
          autoComplete="off"
          placeholder="password"
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            },
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
        <div />

        {/* begin::Link */}
        <Link to="/auth/forgot-password" className="link-primary d-none">
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label fw-bold">Login</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Loading...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>

          {loading2 && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Loading...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
      </div>
      {/* end::Action */}

      <div className="text-gray-500 text-center fw-semibold fs-6 d-none">
        Not a Member yet?{' '}
        <Link to="/auth/registration" className="link-primary">
          Sign up
        </Link>
      </div>
    </form>
  );
}
